@import '../../config';

.stats {
  max-width: 1400px;
  margin: auto;

  &__header {
    margin-bottom: 15px;
    background: white;
    box-shadow: 0px 0px 7px 0px #d8d8d88a !important;
    width: 100%;
    border-radius: 10px;
    min-height: 100px;
    padding: 30px;
    overflow: auto;

    &--grey {
      background: #f8f8f8;
    }

    &--client {
      display: flex;
      flex-direction: row;
      padding-bottom: 15px;
    }
    &-select-client {
      @include mobileView {
        width: initial;
      }
    }
  }

  &__widgets {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    @include mobileView {
      flex-direction: column;
    }
    .dashboard-widget {
      @include mobileView {
        min-width: 84vw !important;
      }
    }
  }

  &__dates {
    &__wrapper {
      width: 300px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__data-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
  }

  &__data-container {
    margin-top: 20px;
    display: flex;
    @include mobileView {
      flex-direction: row;
      width: 98vw;
      overflow: auto;
    }
  }

  &__table {
    margin-top: 50px;
    margin-right: 30px;
  }

  &__graph-container {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .recharts-responsive-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__tabs {
    // filter: drop-shadow(0px 4px 20px rgba(100, 100, 100, 0.25));
    box-shadow: 0px 0px 8px 0px rgba(201, 201, 201, 0.54);
    margin-bottom: 20px;
    max-width: 75%;
    margin: auto;
  }
}
