@import '../../../../../config';

.appointment-stats-graph {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 0px;

  &__data-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;

    @include mobileView {
      justify-content: flex-start;
    }

    &__charts {
      position: relative;
      height: 100%;
      width: 100%;
      padding-right: 20px;
    }

    &__main-chart {
      background: white;
      min-width: 550px;
      max-width: 650x;
      margin-right: 10px;
      box-shadow: 0px 0px 0px 1px rgba(189, 189, 189, 0.6);

      min-height: 330px;
      max-height: 330px;
      border-radius: 4px;

      @include mobileView {
        width: 100vw !important;
        min-width: auto;
        margin-right: 0px;
      }

      @media (min-width: 992px) {
        width: 50%;
        height: 45vh;
        padding-bottom: 50px;
      }

      @media (min-width: 1600px) {
        width: 55%;
        height: 45vh;
        padding-bottom: 50px;
      }

      .recharts-responsive-container {
        margin-right: 20px;
      }

      &__menubar-buttons {
        & > div {
          font-weight: 300;
          border: solid 1px rgb(238, 238, 238);
          border-radius: 5px;
          padding: 2px 5px;
          cursor: pointer;
          font-size: 13px;

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }

      &__menubar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        padding-bottom: 7px;
        padding-top: 14px;
        margin-bottom: 13px;
        border-bottom: 1px solid rgb(238, 238, 238);
        font-weight: 600;
        font-size: 14px;

        &-buttons {
          display: flex;
          justify-content: space-between;
          margin-top: -3px;

          &--highlighted {
            cursor: pointer;
            border: solid 1px rgb(0, 63, 92) !important;
            color: rgb(0, 63, 92);
          }
        }
      }
    }

    &__container {
      min-width: 600px;
      display: flex;
      flex-direction: row;

      @include mobileView {
        flex-direction: column;
        width: 100vw;
        margin-left: 0px;
        margin-top: 16px !important;
        min-width: 100vw;
      }
      @media (max-width: 1200px) {
        margin-top: 30px;
      }
      @media (min-width: 1200px) {
        width: 36%;
      }
    }

    &__pie-chart {
      background: white;
      height: 370px;
      width: 331px;
      margin-right: 20px;
      box-shadow: 0px 0px 3px 1px rgba(189, 189, 189, 0.6);

      min-height: 340px;
      border-radius: 4px;
      @include mobileView {
        width: 100vw;
      }

      @media (min-width: 1200px) {
        height: 45vh;
        width: 50%;
        padding-bottom: 50px;
      }

      .recharts-responsive-container {
        min-height: 300px;
        display: flex;
        align-items: center;
      }

      &__menubar {
        display: flex;
        padding: 15px;
        border-bottom: 1px solid rgb(238, 238, 238);
        font-weight: 600;
        padding-bottom: 9px;
        margin-bottom: 13px;
        font-size: 14px;
      }
      &__dropdown {
        color: #42a5f5;
      }
    }

    &__legend {
      box-shadow: 0px 0px 3px 1px rgba(189, 189, 189, 0.6);

      background: white;
      border-radius: 4px;
      height: 400px;
      min-height: 300px;
      width: 220px;
      margin-right: 10px;
      @include mobileView {
        width: auto;
        margin-top: 3%;
      }

      @media (min-width: 1200px) {
        height: 45vh;
        width: 50%;
      }
    }
  }
}
