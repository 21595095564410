@import '../../config';

.appointment-card {
  &__card {
    box-shadow: 1px 1px 15px rgb(226, 226, 226);
    border-radius: 10px;
    box-shadow: $box-shadow !important;
    width: 100%;
    margin-bottom: 20px;
    max-width: 500px;

    @include desktopView {
      margin-bottom: 0px;
    }

    &:hover {
      box-shadow: 1px 1px 6px rgb(226, 226, 226) !important;
      cursor: pointer;
    }

    &__content {
      border-radius: 10px 10px 0 0;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      padding-top: 15px;
      line-height: normal;
      height: 159px;
      justify-content: space-between;
    }
    &__meta-notice {
      padding: 10px 0 0px 0;
      color: black !important;
      font-size: 0.9rem !important;
      font-weight: 600;
    }
    &__header {
      font-size: 20px;
      font-weight: 900;
      color: black;
      margin-bottom: 8px;
    }
    &__test-type {
      color: $color;
      font-size: medium;
      padding: 0.5rem 0 0.5rem 0;
      font-weight: 900;

      &__logo {
        float: right;
        padding-right: 1rem;
      }
    }

    &__extra-content {
      background-color: white;
      display: flex;
      justify-content: space-between;
      padding: 17px 15px 9px 15px;
      border-radius: 0 0 10px 10px;

      &__total {
        color: black;
      }
      &__price-tag {
        display: flex;
        flex-direction: column;

        .meta {
          padding-left: 50px;
          color: #6b779a;
          font-size: 10px;
        }
      }
      &__currency {
        color: #6b779a;
        font-size: medium;
        font-weight: bold;
        padding: 0 5px 0 5px;
      }
      &__price {
        color: black;
        font-size: 30px;
        font-weight: bolder;
      }
    }
  }
}
