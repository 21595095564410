@import '../../config';

.email-settings {
  padding: 0 20px;
  max-width: 1400px;
  min-width: 800px;
  margin: auto;

  @include mobileView {
    padding: 0 0px;
    min-width: 98vw;
  }

  &__loader {
    min-height: 450px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    margin-bottom: 30px;

    @include mobileView {
      padding: 10px;
      flex-direction: column-reverse;
    }

    &__side-bar {
      width: 40%;
      min-width: 40%;

      @include mobileView {
        width: 100%;
        min-width: 100%;
        margin-top: 30px;
      }
    }

    &__email-wrapper {
      margin-left: 25px;

      @include mobileView {
        margin-left: 0;
      }
    }

    &__email {
      background: white;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0px 0px 7px 0px #d8d8d88a;
    }
  }
}
