@import '../../../config';

.email-activity {
  padding: 0 20px;
  max-width: 1200px;
  min-width: 800px;
  margin: auto;

  @include mobileView {
    padding: 0 0px;
    min-width: 98vw;
  }

  &__loader {
    min-height: 450px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include mobileView {
      flex-direction: column;
      align-items: flex-start;
    }

    &__select {
      height: 40px !important;

      .ui.selection.dropdown {
        height: 40px !important;
        min-height: 40px !important;
        max-height: 40px !important;
      }

      .ui.selection.dropdown > .dropdown.icon {
        padding: 0 !important;
        margin: auto;
      }
    }
  }

  &__load-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__search-inputs {
    display: flex;
    .search-bar {
      @include mobileView {
        width: 74vw !important;
        margin-top: 12px;
      }
    }
    button {
      height: 40px !important ;
      @include mobileView {
        height: 40px !important;
        margin-top: 14px;
      }
    }
  }

  &__table {
    margin-bottom: 30px;

    @include mobileView {
      min-width: max-content;
    }
    &__wrapper {
      margin-top: 17px;

      @include mobileView {
        overflow: auto;
        width: 98vw;
      }
    }
  }
}
