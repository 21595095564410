.side-bar-menu {
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 3px 1px rgba(189, 189, 189, 0.6);
  border-radius: 4px 4px 0px 0px;

  &__content {
    width: 100%;

    &__item {
      width: 100%;
      padding-left: 30px;
      height: 80px;
      display: flex;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      border-left: solid 3px transparent;
      color: #1b1b1b;
      margin-right: 15px;

      &--selected {
        border-right: solid 6px #67d264;
        border-radius: 4px 0 0 4px;
        height: 100%;
        height: 80px;
        right: 0;
      }

      &__icon-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        justify-content: center;
        display: flex;
        padding-top: 10px;
        margin-right: 20px;
      }

      &__icon {
        margin-right: 18px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background: #f2f8fc;
        box-decoration-break: clone;
        // border-left: solid 3px #4698e7;
      }
    }
  }
}
