.sign-canvas {
  position: relative;
  display: flex;
  flex-direction: row;

  &__canvas {
    border: solid 1px black;
    border-radius: 5px;
  }

  &__color {
    margin-left: 5px;

    &__button {
      cursor: pointer;
      border-radius: 5px;
      height: 25px;
      width: 25px;
      margin-bottom: 5px;

      &--green {
        background: green;
      }
      &--brand {
        background: #5c53f3;
      }
      &--blue {
        background: #16264c;
      }
      &--red {
        background: red;
      }
      &--black {
        background: black;
      }
    }
  }

  &__undo {
    position: absolute;
    top: 5px;
    left: -50px;
    border: solid 1px black;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #f2f8fc;
    }

    &:active {
      border: solid 1px #4d4d4d;
    }

    i.icon,
    i.icons {
      margin: 0 !important;
    }
  }
}
