.rc-switch {
  width: 40px;
  height: 20px;
}

.rc-switch:after {
  height: 16px;
  width: 16px;
}

.rc-switch-checked:after {
  left: 20px;
}
