@import '../../../config';

.appointment-stats {
  max-width: 2000px;
  min-width: 1220px;
  margin: auto;
  height: calc(100% - 80px);

  @include mobileView {
    min-width: initial;
  }

  &__widgets-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 900px;
  }

  &__header-wrapper {
    max-width: 1306px;
    min-width: 1220px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 30px;
    align-items: center;

    @include mobileView {
      margin-bottom: 15px;
      min-width: initial;
      align-items: center;
    }
  }
  &__actions {
    display: flex;
    position: relative;
    align-items: end;
    justify-content: flex-end;

    @include mobileView {
      flex-direction: column;
      margin-top: 30px;
    }
  }

  &__date-filter-label {
    padding: 5px 10px;
  }

  &__date-wrapper {
    position: relative;
    display: flex;

    & > div {
      margin-left: 10px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__date-tag {
      position: absolute;
      top: -17px;
      left: 0px;
    }

    .react-date-picker__wrapper {
      border-radius: 4px !important;
      height: 40px !important;
    }
  }

  &__widgets {
    padding: 0 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10px 0;

    @include mobileView {
      flex-direction: column;
      justify-content: flex-start;
      height: initial;
      min-height: initial;
      padding: 0;
    }
  }

  &__widgets-item {
    border-radius: 4px;
    padding-left: 30px;
    // padding-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 25%;
    min-width: 180px;
    height: 200px;
    min-height: 200px;
    margin-right: 20px;
    background: white;
    box-shadow: 0px 0px 0px 1px rgba(189, 189, 189, 0.6);

    @include mobileView {
      width: 100%;
      margin-right: 0;
    }

    .recharts-default-legend {
      line-height: 19px !important;
    }
  }

  &__graph {
    display: flex;
    padding: 0 40px;
    gap: 15px;
    margin-bottom: 15px;
    margin-top: 15px;

    @include mobileView {
      flex-direction: column;
      padding: 0;
    }
  }

  &__tests {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 15px;

    &__wrapper {
      background: white;
      padding-top: 0px;
      box-shadow: 0px 0px 0px 1px rgba(189, 189, 189, 0.6);
      border-radius: 4px;
      height: 100%;
      overflow: auto;
    }
  }

  &__payment {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 15px;

    &__wrapper {
      width: 100%;
      background: white;
      padding: 10px 40px;
      padding-bottom: 0;
      box-shadow: 0px 0px 0px 1px rgba(189, 189, 189, 0.6);
      border-radius: 4px;
      height: 200px;
      width: 50%;

      @include mobileView {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  &__loader-wrapper {
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      margin: auto;
    }
  }
}
