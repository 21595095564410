@import '../../config';

$browser-top-dot-width: 6px;

@mixin browser-top-dots {
  content: '';
  display: block;

  width: $browser-top-dot-width;
  height: $browser-top-dot-width;
  background-color: white;
  border-radius: 100%;
}

.login-preview-aspect-ratio {
  position: relative;
  width: 60%;
  padding-bottom: 35%;
  @include mobileView {
    display: none;
  }
}

.login-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  border-radius: 4px;
  background-color: #4d7903;
  border: solid 1px grey;

  @include mobileView {
    display: none;
  }

  &__header {
    position: relative;
    top: 0;
    height: 25px;
    left: 0;
    width: 100%;
    background: #525252;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 4px 4px 0;

    &::before {
      @include browser-top-dots;
      margin-left: $browser-top-dot-width * 3;
    }

    &__dots {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;

      &::before {
        @include browser-top-dots;
        left: $browser-top-dot-width * 1.3;
        position: absolute;
        top: 10px;
      }

      &:after {
        @include browser-top-dots;
        position: absolute;
        left: $browser-top-dot-width * 3.5;
        top: 10px;
      }
    }

    &__link {
      width: 100%;
      height: 100%;
      color: white;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__background {
    width: 100%;
  }

  &__content {
    height: 100%;
    width: 100%;

    &__login {
      height: calc(100% - 25px);
      width: 35%;
      background: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      &__logo {
        object-fit: contain;
        max-width: 50%;
        max-height: 40%;
        min-height: 30%;
        margin-top: 10px;
      }

      &__line-wrapper {
        width: 80%;
        top: 40%;
        position: absolute;
      }

      &__line {
        margin: 10% 0;
        border-bottom: solid 1px lightgray;
      }

      &__button {
        position: absolute !important;
        bottom: 15px;
        padding: 3px 18px;
      }
    }
  }
}
