.user-guide {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  iframe {
    height: 100%;
    width: 100%;
    max-width: 1000px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(201, 201, 201, 0.5411764705882353);
  }
}
