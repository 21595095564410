@import '../../../../../config';

.payment-widget {
  &__wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__table-wrapper {
    overflow: auto;
    height: 171px;
  }
}
