@import '../../config';

.login {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;

  @media (max-width: 600px) {
    position: fixed;
  }

  &__image {
    width: calc(60% + 100px);
    flex: 1;
    position: relative;
    @include mobileView {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--mini {
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(2px);
      transition: opacity ease-in 1000ms;
    }
  }

  &__card {
    height: 100%;
    width: 40%;
    max-width: 440px;
    min-width: 380px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__overlay {
      box-shadow: 0px 0px 10px 0px grey;
      background: white;
      height: 100%;
      width: calc(100% + 44px);
      margin-left: 40px;
      z-index: 1;
      @include mobileView {
        width: calc(100% + 29px);
        margin-left: 37px;
      }
    }

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
      max-width: 450px;
      width: 100%;
      height: 100%;
      margin: auto;

      @media (max-width: 600px) {
        padding-left: 10px;
      }
    }

    &__logo {
      max-width: 400px;
      max-height: 100px;
      position: absolute;
      top: 60px;
      left: 40px;
    }

    &__header {
      flex-wrap: wrap;
      font-weight: bold;
      font-size: 29px;
      letter-spacing: 0.0125em;
      line-height: 30px;
      word-break: break-all;
      text-align: center;
      width: 100%;
      text-align: left;
      margin-bottom: 15px;
    }

    &__input {
      margin-top: 25px;
    }

    &__button {
      margin-top: 20px;
      width: 100% !important;
    }

    &__forgot-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &__forgot {
      border: none;
      height: 16px;
      margin-top: 10px;

      &__text {
        font-size: 13px;
        font-weight: 400;
      }

      :hover {
        .login__card__forgot__text {
          color: #85b7d9;
        }
      }
    }
  }
}

.login-modal {
  width: 350px;
}
.custom-modal-wrapper {
  z-index: 1 !important;
}
