@import '../../config';

.dashboard {
  background: #f8f9ff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mobileView {
    height: auto;
    padding-left: 10px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  &__header-wrapper {
    width: 100%;
    display: inline;
  }

  &__logo {
    margin-top: 3%;
    margin-left: 2%;
    height: 100%;
    width: auto;
    max-width: 450px;
    min-width: 200px;
    max-height: 170px;
    object-fit: contain;
  }

  &__widgets {
    padding: 0 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 290px;
    min-height: 290px;

    @include mobileView {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__footer-wrapper {
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    &__ongoing-courses {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 170px;
      padding-bottom: 5px;
      padding-right: 0;
      border-radius: 8px;
      position: relative;
      margin-top: 20px;

      &__loader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__title {
        max-width: 1200px;
        margin: auto;
      }
    }

    &__map {
      width: 100%;
      position: relative;
      min-width: 220px;

      &__content {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.dashboard-courses {
  max-width: 1200px;
  margin: auto;
  position: relative;
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  padding-bottom: 20px;
}
