@import '../../config';

$highlight-color: #f2f8fc;

$side-bar-width: 90px;
$side-bar-open-width: 90px;
$side-bar-item-height: 77px;

.side-bar {
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: #f8f9ff;

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    width: $side-bar-width;
    overflow-x: hidden;
    background: white;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-radius: 0 4px 0 0;
    box-shadow: 0px 0px 3px 1px rgba(189, 189, 189, 0.5);

    &--open {
      width: $side-bar-open-width;
      transition: width 130ms ease;
      @include mobileView {
        width: 90px !important;
      }
    }

    &--closed {
      width: $side-bar-width;
      transition: width 130ms ease;
      @include mobileView {
        width: 0px !important;
      }
    }

    &--highlight {
      background: $highlight-color;
    }
  }

  &__content {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    width: 100%;
    display: flex;
    height: $side-bar-item-height;
    user-select: none;
    cursor: pointer;
    position: relative;
    align-items: center;
    transition: background 0.3s ease;
    border-left: solid 3px transparent;
    justify-content: center;

    &__highlight {
      border-left: solid 6px #67d264;
      border-radius: 4px 0 0 4px;
      height: 100%;
      height: 60px;
      position: absolute;
      right: 0;
    }

    &:last-of-type {
      margin: 0;
    }

    &:hover {
      opacity: 0.9;
    }

    &__title {
      &-new {
        position: absolute;
        bottom: 14px;
        font-size: 11px !important;
        left: 0;
        text-align: center;
        margin: auto;
        width: 100%;
        text-align: center;
      }

      &--selected {
        color: #53f393;
      }
    }

    &__icon-wrapper {
      position: absolute;
      left: 0;
      top: 5px;
      height: 100%;
      width: $side-bar-width - 8;
      justify-content: center;
      display: flex;
      padding-top: 11px;
    }

    &__icon {
      position: absolute;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      transition: all 0.3s ease;
      padding-top: 2px;
      width: $side-bar-width;
      background: #181f2c;
      z-index: 9;

      &:hover {
        background: $highlight-color;
      }
    }

    &--bottom {
      position: absolute;
      bottom: 0;
      padding-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      &:hover {
        background: initial;
        border-left: solid 3px transparent;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      font-size: 16px;
      height: 100%;
      width: calc(#{$side-bar-open-width} - #{$side-bar-width});

      &--open {
        opacity: 1;
        transition: opacity 320ms ease-in;
      }
      &--closed {
        opacity: 0;
      }
    }

    &__wrapper {
      height: $side-bar-item-height;
    }
  }

  &__sub-menu {
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    height: 100%;
    top: 0;
    right: -310px;
    width: 300px;
    z-index: 1;
  }
}
