$mobile: 600px;
$deskptop: 768px;

$color-primary: white;
$color-secondary: #fff9f6;
$color-green: #1fa97f;
$color-gray: #f8fafc;

$padding: 10px;
$border-radius: 7px;
$button-color: $color-green;
$button-color-hover: #3dcea2;
$text-color: white;
$button-height: 2.6em;
$color: #1fa97f;
$background-color-white: $color-primary;
$background-color-cream: $color-secondary;
$background-color-gray: $color-gray;
$box-shadow: 0px 0px 2px 0px rgba(201, 201, 201, 0.5411764705882353);
$border-color: $color-green;
@mixin mobileView {
  @media (max-width:#{$mobile}) {
    @content;
  }
}

@mixin desktopView {
  @media (min-width:#{$deskptop}) {
    @content;
  }
}
