.dashboard-legend {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__titles {
    padding: 15px;
    border-bottom: 1px solid rgb(238, 238, 238);
    font-weight: 600;
    padding-bottom: 9px;
    font-size: 14px;
  }

  &__type {
    margin-top: 15px;
    font-size: 14px;

    &-list {
      overflow-y: scroll;
      height: 100%;
      padding: 0 15px;
    }

    &--hightlighted {
      color: #42a5f5;
    }
  }

  &__subtitle {
    font-size: 1em;
    font-style: italic;
    color: #a0a3bd;
  }

  &__selection-details {
    padding: 10px 0px 10px 0px;
    border-top: 1px solid rgb(238, 238, 238);
    display: flex;
    justify-content: space-between;
  }
}
