.ui.table {
  border: none !important;
  box-shadow: 0px 0px 3px 0px rgba(201, 201, 201, 0.5411764705882353) !important;

  thead th {
    background: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-bottom: solid 1px #dbdbdb !important;
    color: rgba(0, 0, 0, 0.6) !important;
    padding-left: 10px !important;
  }

  .table-row {
    font-size: 16px;
    // cursor: pointer;

    &__checkbox {
      margin: auto;
    }

    &__cell {
      &__link {
        cursor: pointer;
      }

      &--center {
        text-align: center;
      }
    }
  }
}

.ui.table thead tr:first-child > th:first-child {
  border-radius: 2px 0 0 0 !important;
}
.ui.table thead tr:first-child > th:last-of-type {
  border-radius: 0 2px 0 0 !important;
}
.table {
  border-radius: 2px !important;
}

.ui.table thead th {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: black !important;
  line-height: 20px !important;
}

.ui.table tr td {
  border-bottom: solid 1px #f3f3f3;
  border-top: none !important;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  line-height: 17px !important;
  color: #1b1b1b;
  font-weight: 300 !important;
}
