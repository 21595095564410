.Toastify__toast-container {
  min-width: 320px;
  width: initial;
  max-width: 400px;
  border-radius: 0px;
}

.Toastify__toast {
  border-radius: 0px;
}

.Toastify__toast-body {
  padding-right: 15px;
}

.Toastify__toast--success {
  background: #29b25b !important;
}
