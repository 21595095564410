.add-connection {
  &__content {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    &__buttons {
      margin-top: 30px;
    }
  }

  &__input {
    margin: 5px 0;
    display: flex;
    position: relative;

    &__icon {
      position: absolute;
      margin-top: 43px;
      left: -20px;
    }

    &__button {
      position: absolute !important;
      margin-top: 31px;
      left: 310px;
    }
  }
}
