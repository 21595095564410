.charts {
  &__custom-tooltip {
    background: white;
    border-radius: 10px;
    padding: 15px;
    border: solid 1px rgba(201, 201, 201, 0.7);
    box-shadow: 0px 0px 10px 0px rgba(201, 201, 201, 0.45);
    display: flex;
    flex-direction: column;
  }

  &__label {
    padding: 7px;
  }
}
