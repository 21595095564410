.course-creation-buttons {
  position: absolute;
  top: 10px;
  width: 100%;
  max-width: 1280px;
  min-width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  z-index: 9;

  &__back {
  }

  &__exit {
  }
}
