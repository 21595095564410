.react-date-picker {
  border-radius: 4px;

  &__calendar {
    z-index: 2 !important;
  }

  &__inputGroup {
    line-height: 1.25em !important;
  }

  &__button {
    padding: 0;
  }

  &--open {
    .react-date-picker__wrapper {
      border: solid 1px #5c53f3;
    }
  }

  &__wrapper {
    height: 35px;
    outline-width: 0;
    font-size: 17px;
    color: #1b1b1b;
    font-weight: 200;
    background: transparent;
    border: solid 1px #bdbdbd;
    border-radius: 4px;
    padding: 10px;
    height: 45px;
    background: white;
    margin-top: 0 !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &__inputGroup {
    &__input {
      text-align: center;
      font-size: 17px;
      color: #1b1b1b;
      font-weight: 200;

      ::placeholder {
        color: #1b1b1b;
        opacity: 0.8;
        transition: all 0.3s ease;
        font-weight: 200;
        font-size: 16px;
      }

      :-ms-input-placeholder {
        color: #bdbdbd;
      }

      ::-ms-input-placeholder {
        color: #bdbdbd;
      }

      &:focus {
        outline: none;

        ::placeholder {
          color: #bdbdbd;
          opacity: 1;
        }

        :-ms-input-placeholder {
          color: #1b1b1b;
        }

        ::-ms-input-placeholder {
          color: #1b1b1b;
        }
        &:invalid {
          background: none;
        }
      }
    }

    &__divider {
      padding: 1px 1px;
    }
  }
}

.react-calendar {
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 12px 0px #bdbdbd;

  &__navigation button:enabled:hover,
  &__navigation button:enabled:focus {
    background-color: rgba(102, 102, 102, 0.18) !important;
    border-radius: 4px;
  }

  &__navigation {
    padding: 10px;
    background: #5c53f3;
    border-radius: 4px 4px 0 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      height: 32px;
      color: white;
      font-weight: 200;
      font-size: 18px;
      margin-top: 6px;
    }

    &__label {
      padding-top: 5px;
      margin-top: 4px !important;
    }
  }

  &__viewContainer {
    padding: 20px;
    padding-top: 0px;
  }

  &__month-view {
    &__weekdays__weekday {
      color: #1b1b1b;
      abbr[title] {
        text-decoration: none;
      }
    }

    &__days__day--weekend {
      color: #1b1b1b;
    }

    &__days__day--neighboringMonth {
      color: #bdbdbd;
    }
  }

  &__tile {
    &:enabled:hover {
      background-color: #fafafa;
      border-radius: 6px;
      color: black;
    }

    &--active {
      background: transparent !important;
      border-radius: 6px;
      border: solid 1px #5c53f3 !important;
      color: #5c53f3 !important;
    }
  }
}

.react-calendar__navigation button[disabled] {
  background-color: initial;
}
