.course-location {
  &__info {
    margin-bottom: 20px;

    &__header {
      display: flex;

      &__icon {
        font-size: 19px;
        margin-right: 10px;
      }

      &__text {
        margin-bottom: 10px;
      }

      &__sub-text {
        margin-left: 5px;
      }
    }

    &__select {
      margin: 15px 0;
    }
  }

  &__map {
    height: 200px;
    width: 300px;
    min-height: 200px;
    min-width: 300px;
  }

  &__address-search {
    width: 500px;
  }

  &__map-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__text {
      max-width: 450px;
      margin-left: 15px;
      & > div {
        &:first-of-type {
          margin-bottom: 7px;
        }
      }
    }
  }
}
