.corp-dash {
  &__header {
    text-align: center;
  }
  &__clickable {
    cursor: pointer;
  }

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__empty {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__search {
    display: flex;
    button {
      margin-left: 20px;
    }
  }

  &__revoked-status {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    margin-left: 16px;

    &--green {
      background: #5bc592;
    }

    &--red {
      background: red;
    }
    &--orange {
      background: #ff9b71;
    }
  }
}
