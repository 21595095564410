.map {
  width: 620px;
  height: 300px;
  border-radius: 5px;

  & > div {
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
}

.location-adding {
  min-height: 320px;
  margin: 5px !important;
  margin-bottom: 0px !important;
  padding: 0 !important;
}
