html {
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  font-smooth: always;
  text-size-adjust: 100%;
  box-sizing: border-box;
  @include default-font;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body,
html {
  margin: 0;
  overflow-x: hidden;
  font-size: 14px;
  line-height: 1.2;
  @include default-font;
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

svg:not(:root) {
  overflow: hidden;
}
