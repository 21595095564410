@import '../../../../config';

.dashboard-widget {
  border-radius: 4px;
  padding-left: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 25%;
  min-width: 180px;
  height: 200px;
  min-height: 200px;
  margin-right: 4%;
  background: white;
  box-shadow: 0px 0px 3px 1px rgba(189, 189, 189, 0.6);

  @include mobileView {
    min-width: 100vw;
    margin-right: 0px !important;
    margin-top: 5% !important;
  }

  @media (min-width: 1600px) {
    width: 20%;
    justify-content: center;
  }

  &:last-of-type {
    margin: 0;
  }

  &__number {
    margin-top: 30px;
  }

  &__header {
    margin-bottom: 3px;
  }

  &__underline {
    background: #9c9c9c;
    height: 4px;
    border-radius: 4px;
    width: 60px;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  &__icon {
    display: none;
    position: absolute;
    bottom: -9px;
    left: 0;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px #9898988a;
    .dashboard-widget__underline {
      background: #53f393;
    }
    .dashboard-widget__icon {
      display: initial;
    }
  }
}
