@import '../../config';

.header-bar {
  .ui.dropdown .menu {
    border-radius: 10px !important;
    padding: 6px !important;
  }

  &__notif,
  .ui.floating.dropdown > .menu {
    border-radius: 10px !important;

    &__item {
      width: 250px !important;
      white-space: normal !important;
      border-radius: 10px !important;
    }
  }

  &__dev-wrapper {
    border: solid 1px #19b970;
    padding: 5px;
    border-radius: 5px;
  }

  &__notif-circle {
    background: #ff9b71;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 22px;
    top: 0px;
    width: 20px;
    height: 20px;
    border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
    transform: rotate(-127deg);

    &__text {
      transform: rotate(127deg);
      padding-top: 2px;
    }
  }
  &__notifications {
    position: relative;
    margin-right: 30px;
    @include mobileView {
      right: 50px !important;
      top: -10px !important;
    }
  }

  &__logo {
    @include mobileView {
      display: none !important;
    }
  }
  &__icon {
    @include mobileView {
      position: absolute;
      top: 16px;
    }
    @include desktopView {
      display: none !important;
    }
  }

  &__notifcations {
    @include mobileView {
      right: 130px;
      top: 22px;
    }
  }

  .icon {
    color: #1b1b1b !important;
  }
}

.profile-dropdown {
  margin-right: 20px;
  margin-left: 20px;
  flex-direction: column;
  display: flex;
  right: 0px;
  top: 10px;
  z-index: 3;
  border-radius: 10px;
  max-width: 240px;
  overflow: hidden;
  box-shadow: none;
  position: initial;
  background: initial;
  animation: fadein 0.2s;

  &--toggled {
    box-shadow: 0px 0px 4px 0px #8e8d8d8a;
    position: absolute;
    background: white;
    animation: fadein 0.2s;
  }
  &--username {
    display: block;
    @include mobileView {
      display: none !important;
    }
  }
  &__name-avatar {
    @include mobileView {
      position: absolute;
      top: 0px;
      right: 5px;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
